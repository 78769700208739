<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else-if="data">
      <h3 style="margin-bottom: 25px">
        {{ $l.get("genomemoset-create.header") }}
      </h3>
      <GenomemosetForm
        :genomemoset="genomemoset"
        :onSubmit="onSubmit"
        :diagnostics="data.diagnostics"
      />
    </div>
  </div>
</template>

<script>
import InitGenomemosetCreateInteractor from "@/business/genomemoset-create/init-genomemoset-create";
import SubmitFormInteractor from "@/business/genomemoset-create/submit-form";
import GenomemosetCreateScreenController from "@/adapters/controllers/screen-genomemoset-create";
import GenomemosetForm from "@/application/components/dna/GenomemosetForm";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "genomemoset-create",
  components: { GenomemosetForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initGenomemosetCreate: null,
        submitForm: null
      },
      data: null,
      isLoading: false,
      genomemoset: {
        name: "",
        aboutPtBrText: "",
        aboutEnUsText: "",
        aboutEsEsText: "",
        criteriaText: "",
        variables: []
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(GenomemosetCreateScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initGenomemosetCreate = this.$injector.get(
      InitGenomemosetCreateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initGenomemosetCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.genomemoset);
    }
  }
};
</script>

<style lang="scss"></style>
